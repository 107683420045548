<template>
  <v-app>
    <HeaderTemplate></HeaderTemplate>

    <v-main>
      <router-view />
    </v-main>

    <FooterTemplate></FooterTemplate>
  </v-app>
</template>

<script>
import HeaderTemplate from "@/components/template/Header.vue";
import FooterTemplate from "@/components/template/Footer.vue";

export default {
  name: "App",

  components: {
    HeaderTemplate,
    FooterTemplate,
  },

  data: () => ({
    meta: {
      app_name: process.env.VUE_APP_NAME,
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  mounted() {
    document.title = `Red Global MX | ${this.meta.app_name}`;
  },
};
</script>
