<template>
  <v-container>
    <v-row class="my-8">
      <v-col cols md="6">
        <CollageSlideComponent
          :slides="collage1"
          :animationTime="700"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage2"
          :animationTime="800"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage3"
          :animationTime="900"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage4"
          :animationTime="1000"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage5"
          :animationTime="1100"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage6"
          :animationTime="1200"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage7"
          :animationTime="1300"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage8"
          :animationTime="1400"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage9"
          :animationTime="1500"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage10"
          :animationTime="1600"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage11"
          :animationTime="1700"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage12"
          :animationTime="1800"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage13"
          :animationTime="1900"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage14"
          :animationTime="2000"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage15"
          :animationTime="2100"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <CollageSlideComponent
          :slides="collage16"
          :animationTime="2200"
          style="width: 165px; height: 165px"
          class="float-left"
        ></CollageSlideComponent>

        <!--v-row>
          <v-col cols md="3">
            <vueper-slides
              fade
              autoplay
              :arrows="false"
              :bullets="false"
              transition-speed="250"
              duration="2000"
              :slide-ratio="1"
            >
              <vueper-slide
                v-for="(item, i) in currentCollage"
                :key="i"
                :title="item.name"
                :content="item.name"
                :image="`${meta.storage_files + item.formats.thumbnail.url}`"
              >
              </vueper-slide>
            </vueper-slides>
          </v-col>

          <v-col cols md="3">
            <vueper-slides
              fade
              autoplay
              duration="2000"
              transition-speed="250"
              :arrows="false"
              :bullets="false"
              :slide-ratio="1"
            >
              <vueper-slide
                v-for="(slide, i) in slides"
                :key="slide.id"
                :title="slide.title"
                :style="'background-color: ' + colors[i % 4]"
              />
            </vueper-slides>
          </v-col>
        </v-row-->

        <!--v-row>
          <v-col md="3" class="">
            <div
              :class="`gradient-yellow`"
              style="width: 100%; height: 100%; position: relative; z-index: 99"
            >
              <v-img
                :src="`${meta.storage_files}/uploads/RGMX_Proyectos_ce564a2e07.jpg`"
                :lazy-src="
                  meta.storage_files + '/uploads/RGMX_Proyectos_ce564a2e07.jpg'
                "
                class="mx-auto over--grey"
                aspect-ratio="1"
              >
                <section
                  :class="`fill-height gradient-${selectedColor}`"
                ></section>
              </v-img>
            </div>
          </v-col>
        </v-row-->

        <!--transition-group tag="div" class="img-slider" name="slide">
            <div v-for="n in [currentImg]" v-bind:key="n">
              <v-img
                :src="`${
                  imgList[Math.abs(currentImg) % imgList.length]
                }?text=${n}`"
                aspect-ratio="1"
              >
                <div :class="`fill-height gradient-${selectedColor}`"></div>
              </v-img>
            </div>
          </transition-group-->

        <!--v-img
            v-for="i in 4"
            :src="`${meta.storage_files}/uploads/RGMX_Proyectos_ce564a2e07.jpg`"
            lazy-src="/assets/images/back/background5.jpg"
            class="mx--auto"
            max-width="24%"
            aspect-ratio="1"
          ></v-img-->

        <!--div
            style="width: 22%"
            v-for="n in 16"
            :key="n"
            class="float-left"
            cols="3"
          >
            <v-img
              :src="`https://picsum.photos/500/500?grayscale&image=${
                n * 5 + 10
              }`"
              :lazy-src="`https://picsum.photos/10/6?grayscale&image=${
                n * 5 + 10
              }`"
              aspect-ratio="1"
            >
              <div :class="`fill-height gradient-${selectedColor}`"></div>
            </v-img>
          </div-->
        <!--slither-slider
          :options="{
            autoplay: true,
            transition: 'fade',
            dots: false,
            controls: false,
            fullscreen: false,
          }"
        >
          <div
            style="width: 165px; height: 165px"
            class="float-left"
            v-for="item in currentCollage"
            :key="item.name"
            :class="`gradient-${item.color}`"
          >
            <v-img
              :src="meta.storage_files + item.url"
              :lazy-src="meta.storage_files + item.url"
              class="over--grey"
              aspect-ratio="1"
              style=""
            />
            <div
              v-if="item.color != 'white'"
              :class="`gradient-${item.color}`"
              style="
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 99;
                top: -165px;
              "
            ></div>
          </div>
        </slither-slider-->
        <!--div
            style="width: 22%"
            class="float-left"
            v-for="item in currentCollage"
            :key="item.name"
            :class="`gradient-${item.color}`"
          >
            <v-img
              :src="meta.storage_files + item.pictures.url"
              :lazy-src="meta.storage_files + item.pictures.url"
              aspect-ratio="1"
            >
              <div :class="`fill-height gradient-${item.color}`"></div>
            </v-img>
          </div-->
      </v-col>

      <v-col cols md="6" class="d-flex justify-center flex-column">
        <h1 class="display-4 text-uppercase font-weight-black">
          {{ title }}
        </h1>
        <h2 class="display-3 text-uppercase font-weight-black">
          {{ subtitle }}
        </h2>
        <h3 class="display-1 text-uppercase font-weight-light mb-8">
          <span v-html="slogan"></span>
          <div class="line-black float-right mt-1"></div>
        </h3>

        <div v-html="content"></div>

        <!--<p class="">
            La Red Global MX está compuesta por 72 Capítulos distribuidos en 35
            países y 19 Nodos ubicados en 18 estados de la República Mexicana.
          </p>
          <p class="">
            En conjunto, la RGMX cuenta con una membresía de 6,500 personas.
          </p>
          <p class="">
            Para conocer el trabajo y áreas de especialización de cada Capitulo
            y Nodo, así como para contactarlos, da clic en pin de interés
          </p>-->
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import wsApiHome from "@/services/api-home";
import CollageSlideComponent from "@/components/HomePage/CollageSlide.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "CollageComponent",

  props: {
    title: String,
    subtitle: String,
    slogan: String,
    content: String,
  },

  components: {
    CollageSlideComponent,
    VueperSlides,
    VueperSlide,
  },

  data: () => ({
    currentCollage: {},
    collage1: {},
    collage2: {},
    collage3: {},
    collage4: {},
    collage5: {},
    collage6: {},
    collage7: {},
    collage8: {},
    collage9: {},
    collage10: {},
    collage11: {},
    collage12: {},
    collage13: {},
    collage14: {},
    collage15: {},
    collage16: {},

    colors: ["yellow", "green", "pink", "blue"],
    //colors: ["255,160,0", "104,159,56", "216,27,96", "2,132,155"],
    selectedColor: null,

    slides: [
      {
        title: "Slide #1",
        image: "/uploads/Nodo_Michoacan_7626e38be3.jpg",
      },
      {
        title: "Slide #2",
        image: "/uploads/Nodo_Michoacan_7626e38be3.jpg",
      },
    ],

    cycle: true,
    show: false,
    slide: 0,

    meta: {
      app_name: process.env.VUE_APP_NAME,
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  created() {
    const idx = Math.floor(Math.random() * this.colors.length);
    this.selectedColor = this.colors[idx];
  },

  mounted() {
    this.loadCollage();

    setInterval(() => {
      this.currentImg = this.currentImg + 1;
    }, 3000);
  },

  methods: {
    loadCollage() {
      wsApiHome.getCollage().then(
        (response) => {
          this.currentCollage = response.data.data.image;

          this.collage1 = response.data.data.image[0].pictures;
          this.collage2 = response.data.data.image[1].pictures;
          this.collage3 = response.data.data.image[2].pictures;
          this.collage4 = response.data.data.image[3].pictures;
          this.collage5 = response.data.data.image[4].pictures;
          this.collage6 = response.data.data.image[5].pictures;
          this.collage7 = response.data.data.image[6].pictures;
          this.collage8 = response.data.data.image[7].pictures;
          this.collage9 = response.data.data.image[8].pictures;
          this.collage10 = response.data.data.image[9].pictures;
          this.collage11 = response.data.data.image[10].pictures;
          this.collage12 = response.data.data.image[11].pictures;
          this.collage13 = response.data.data.image[12].pictures;
          this.collage14 = response.data.data.image[13].pictures;
          this.collage15 = response.data.data.image[14].pictures;
          this.collage16 = response.data.data.image[15].pictures;

          //this.sanitizeHtml = JSON.stringify(this.currentCollage);
          //this.decodeJson = this.addUrlToImage(this.sanitizeHtml);
          //this.encodeJson = JSON.parse(this.decodeJson);
        },
        (error) => {
          this.currentCollage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    addUrlToImage(htmlCode) {
      let url = htmlCode.replaceAll("/uploads/", "thumbnail_");
      return url;
    },
  },
};
</script>
