import Vue from "vue";
import VueRouter from "vue-router";
import HomeVersionOneComponent from "@/components/HomePage/HomeVersionOne.vue";
import HomeVersionTwoComponent from "@/components/HomePage/HomeVersionTwo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeVersionOne",
    component: HomeVersionOneComponent,
  },

  {
    path: "/homev2",
    name: "HomeVersionTwo",
    component: HomeVersionTwoComponent,
  },

  /*
   * !Capitulos
   */
  {
    path: "/capitulo/:page",
    name: "PageChapters",
    component: () =>
      import(/* webpackChunkName: "chapters" */ "@/views/ChapterView.vue"),
  },
  {
    path: "/capitulos/:chapter",
    name: "ChaptersShow",
    component: () =>
      import(/* webpackChunkName: "chapters" */ "@/views/ChapterShow.vue"),
  },

  /*
   * !Paginas
   */
  {
    path: "/rgmx/:page",
    name: "PageRgmx",
    component: () =>
      import(/* webpackChunkName: "rgmx" */ "@/views/RgmxPageView.vue"),
  },

  {
    path: "/pilares/:page",
    name: "PilaresRgmx",
    component: () =>
      import(/* webpackChunkName: "rgmx" */ "@/views/RgmxPilarsPageView.vue"),
  },

  {
    path: "/regiones/:region",
    name: "RegionsPage",
    component: () =>
      import(/* webpackChunkName: "regions" */ "@/views/RegionsView.vue"),
  },

  /*
   * !Nodos
   */
  {
    path: "/nodos/",
    name: "NodesView",
    component: () =>
      import(/* webpackChunkName: "nodes" */ "@/views/NodesView.vue"),
  },
  {
    path: "/nodos/:page",
    name: "PageNode",
    component: () =>
      import(/* webpackChunkName: "pages" */ "@/views/NodesPageView.vue"),
  },
  {
    path: "/nodo/:node",
    name: "NodeShowView",
    component: () =>
      import(/* webpackChunkName: "nodes" */ "@/views/NodeShowView.vue"),
  },

  /*
   * !MX Directo
   */
  {
    path: "/proyectos",
    name: "MxDirectoProjects",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/mxdirect/ProjectsView.vue"
      ),
  },
  {
    path: "/proyectos/:project",
    name: "MxDirectoProjectShow",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/mxdirect/ProjectShowView.vue"
      ),
  },

  /*
   * Directorio
   */
  {
    path: "/directorio",
    name: "Directorio",
    component: () =>
      import(/* webpackChunkName: "directory" */ "@/views/DirectoryView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
