import http from "@/services/http-common";
import authHeader from "@/services/auth-header";

class wsApiRgmx {
  /* Homepage */

  /* RGMX */
  getAllArticles() {
    return http.get(`/articles/`, { headers: authHeader() });
  }
  getMenuRmgx() {
    return http.get(`/pages/?filters[section][$eq]=rgmx`, {
      headers: authHeader(),
    });
  }

  /* Regiones */
  getRegion(region) {
    return http.get(
      `/regions/?filters[title][$eq]=${region}&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&populate[social_media][fields][2]=*&populate[contacto][fields][3]=*&populate[location][fields][4]=*`,
      {
        headers: authHeader(),
      }
    );
  }
  getRegionWithChapters(region) {
    return http.get(
      `/regions/?filters[title][$eq]=${region}&populate[chapters][fields][1]=*&populate[imageCover][fields][1]=url&populate[social_media][fields][2]=*&populate[contacto][fields][3]=*&populate[location][fields][4]=*`,
      {
        headers: authHeader(),
      }
    );
  }
  getAllRegions() {
    return http.get(
      `/regions/?sort=title`, // ?populate[chapters][fields][0]=title&populate[chapters][fields][1]=slug
      {
        headers: authHeader(),
      }
    );
  }
  getRegionsDirectory() {
    return http.get(
      `/regions/?populate[chapters][populate][0]=contacto&populate[imageCover][fields][1]=url`,
      {
        headers: authHeader(),
      }
    );
  }

  /* Pilares */
  getClusterByTemathic(cluster) {
    return http.get(
      `/thematic-clusters/?filters[slug][$eq]=${cluster}&populate[capitulos][populate][0]=imageHeader`,
      {
        headers: authHeader(),
      }
    );
  }

  /* Capitulos */
  getAllChapters() {
    return http.get(`/chapters/?populate=*`, {
      headers: authHeader(),
    });
  }
  getChaptersByRegion(region) {
    return http.get(
      `/chapters/?filters[region][title][$eq]=${region}&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&populate[location][fields][2]=*&sort=title`,
      {
        headers: authHeader(),
      }
    );
  }
  allChaptersByRegion(region) {
    return http.get(`/chapters/?filters[region][title][$eq]=${region}`, {
      headers: authHeader(),
    });
  }
  getAllChaptersById(id) {
    return http.get(`/chapters/?filters[region][id][$eq]=${id}`, {
      headers: authHeader(),
    });
  }
  getChapter(chapter) {
    return http.get(`/chapters/?filters[slug][$eq]=${chapter}&populate=*`, {
      headers: authHeader(),
    });
  }
  getChaptersMap() {
    return http.get(
      `/chapters/?fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[region][fields][4]=name&populate[region][fields][5]=colorPin&populate[contacto][fields][2]=*`, //
      {
        headers: authHeader(),
      }
    );
  }
  getChaptersDirectory() {
    return http.get(`/chapters/?populate=*`, {
      headers: authHeader(),
    });
  }

  /* Nodos */
  getAllNodes() {
    return http.get(`/nodes/?populate[imageHeader][fields][0]=url&sort=title`, {
      headers: authHeader(),
    });
  }
  getNode(node) {
    return http.get(`/nodes/?filters[slug][$eq]=${node}&populate=*`, {
      headers: authHeader(),
    });
  }
  getNodesMap() {
    return http.get(
      `/nodes/?fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[Contacto][fields][4]=*`,
      {
        headers: authHeader(),
      }
    );
  }
  getNodesDirectory() {
    return http.get(
      `/nodes/?populate[Contacto][fields][0]=*`, //populate[Contacto][populate][0]=*
      {
        headers: authHeader(),
      }
    );
  }

  /* Proyectos */
  getAllProjects() {
    return http.get(
      `/projects/?populate[imageCover][fields][0]=url&populate[author][fields][1]=fullName&populate[chapter][fields][2]=title&populate[chapter][fields][3]=slug`,
      {
        headers: authHeader(),
      }
    );
  }
  getAllProjectsFavorites(paginationLimit) {
    return http.get(
      `/projects/?filters[leading][$eq]=true&populate[imageCover][fields][0]=url&populate[author][fields][1]=fullName&populate[chapter][fields][0]=*&pagination[limit]=${paginationLimit}`, //
      {
        headers: authHeader(),
      }
    );
  }
  getProjectsFavorites() {
    return http.get(`/projects/?filters[favorite][$eq]=favorite&populate=*`, {
      headers: authHeader(),
    });
  }
  getProject(project) {
    return http.get(`/projects/?filters[slug][$eq]=${project}&populate=*`, {
      headers: authHeader(),
    });
  }

  /* Mexico en directo */
  getMxDirect() {
    return http.get(`/mxdirect/`, { headers: authHeader() });
  }

  /* Mexicanos talentosos */
  getTalentedMexicans() {
    return http.get(
      `/talented-mexicans/?populate[imageCover][fields][1]=url&pagination[limit]=3&sort[0]=id:asc`,
      {
        headers: authHeader(),
      }
    );
  }

  /* paginas */
  getPages(page) {
    return http.get(
      `/pages/?populate[author][fields][0]=fullName&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&filters[slug][$eq]=${page}&populate[region][fields][0]=*`,
      {
        headers: authHeader(),
      }
    );
  }

  getPagesBySection(section) {
    return http.get(
      `/pages/?populate[author][fields][0]=fullName&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&filters[section][$eq]=${section}`,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new wsApiRgmx();
