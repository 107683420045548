import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VSwitch from "v-switch-case";
//import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
require("moment/locale/es");
import VueCarousel from "vue-carousel";
import SlitherSlider from "slither-slider";

/* Custom Style */
import "@/assets/css/gobmx.css";
import "@/assets/css/styles.css";
import "@/assets/css/wysiwyg.css";
import "animate.css";

Vue.config.productionTip = false;
Vue.use(VSwitch);
Vue.use(VueMeta);
Vue.use(VueMoment, { moment });
Vue.use(VueCarousel);
Vue.use(SlitherSlider);

/*
Vue.use(VueGtag, {
  config: {
    id: "UA-141778381-2",
    params: {
      send_page_view: true,
    },
  },
});
*/

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
