var render = function render(){var _vm=this,_c=_vm._self._c;return _c('slither-slider',{attrs:{"options":{
    autoplay: true,
    transition: 'fade',
    dots: false,
    controls: false,
    fullscreen: false,
    animationDuration: _vm.animationTime,
  }}},_vm._l((_vm.slides),function(item){return _c('div',{key:item.name,class:`gradient--${item.color}`},[_c('v-img',{staticClass:"over--grey",attrs:{"src":_vm.meta.storage_files + item.formats.thumbnail.url,"lazy-src":_vm.meta.storage_files + item.formats.thumbnail.url,"aspect-ratio":"1"}}),_c('section',{class:`fill-height gradient-${item.color}`,staticStyle:{"width":"100%","height":"100%","position":"relative","z-index":"99","top":"-165px"}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }