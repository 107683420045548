import http from "@/services/http-common";
import authHeader from "@/services/auth-header";

class wsApiHome {
  /* Homepage */
  getHomePage() {
    return http.get(
      `/home-page/?populate[pillars][populate][0]=imageCover&populate[projectsConfig][populate][1]=background&populate[talentedMexicansConfig][populate][2]=background&populate[callAction][populate][fields][3]=deep,3`,
      {
        headers: authHeader(),
      }
    );
  }

  getHomePageTotal() {
    return http.get(`/home-page/?populate=deep,3`, {
      headers: authHeader(),
    });
  }

  getHomeMap() {
    return http.get(`/home-page/?populate[home_map][populate][fields][1]=*`, {
      headers: authHeader(),
    });
  }

  getAllArticles() {
    return http.get(`/home-page/`, { headers: authHeader() });
  }

  /* Collage */
  getCollage() {
    return http.get(`/collage/?populate=deep,3`, {
      headers: authHeader(),
    });
  }
}

export default new wsApiHome();
