<template>
  <slither-slider
    :options="{
      autoplay: true,
      transition: 'fade',
      dots: false,
      controls: false,
      fullscreen: false,
      animationDuration: animationTime,
    }"
  >
    <div
      v-for="item in slides"
      :key="item.name"
      :class="`gradient--${item.color}`"
    >
      <v-img
        :src="meta.storage_files + item.formats.thumbnail.url"
        :lazy-src="meta.storage_files + item.formats.thumbnail.url"
        class="over--grey"
        aspect-ratio="1"
        style=""
      />
      <section
        :class="`fill-height gradient-${item.color}`"
        style="
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 99;
          top: -165px;
        "
      ></section>
    </div>
  </slither-slider>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "CollageSlideComponent",

  props: {
    slides: String,
    animationTime: Number,
  },

  components: {
    VueperSlides,
    VueperSlide,
  },

  mounted() {
    //this.slides
  },

  data: () => ({
    meta: {
      app_name: process.env.VUE_APP_NAME,
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),
};
</script>
